import React from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import Newsletters from "../../components/MainPage/Newsletters/Newsletters";
import Copy from "../../components/TradingUniverseComponents/Copy/Copy";
import Deposit from "../../components/TradingUniverseComponents/Deposit/Deposit";
import First from "../../components/TradingUniverseComponents/First/First";
import Funds from "../../components/TradingUniverseComponents/Funds/Funds";
import Group from "../../components/TradingUniverseComponents/Group/Group";
import How from "../../components/TradingUniverseComponents/How/How";
import Leverage from "../../components/TradingUniverseComponents/Leverage/Leverage";
import classes from "./TradingPage.module.scss";
const TradingPage = () => {
  return (
    <div className={["ovf-hidden", classes.trading].join(" ")}>
      <Header />
      <First />
      <Deposit />
      <Copy />
      <How />
      <Leverage />
      <Group />
      <Funds />
      <Newsletters />
      <Footer />
    </div>
  );
};

export default TradingPage;
