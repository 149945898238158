import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Start.module.scss";
import img1 from "../../../assets/img/StartPage/5/1.png";
import { useTranslation } from "react-i18next";

const Start = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.start}>
      <div className={classes.startBody}>
        <div className="container">
          <div className={classes.startRow}>
            <div className={classes.startRowLeft}>
              <div className={[classes.startRowLeftTitle, "font-40"].join(" ")}>
                {t('start_with_title')}
              </div>
              <div
                className={[classes.startRowLeftSubtitle, "font-30"].join(" ")}
              >
                {t('start_with_subtitle')}
              </div>
              <div className={[classes.startRowLeftText, "font-20"].join(" ")}>
                {t('start_with_text')}
              </div>
              <div className={classes.startRowLeftBtn}>
                <Button>{t('btn_open_free_acc')}</Button>
              </div>
            </div>
            <div className={classes.startRowRight}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Start;
