import React from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import classes from "./StartPage.module.scss";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import First from "../../components/StartPageComponents/First/First";
import Second from "../../components/StartPageComponents/Second/Second";
import Three from "../../components/StartPageComponents/Three/Three";
import AccountTypes from "../../components/MainPage/AccountTypes/AccountTypes";
import Newsletters from "../../components/MainPage/Newsletters/Newsletters";
import Start from "../../components/StartPageComponents/Start/Start";
import Security from "../../components/StartPageComponents/Security/Security";
import Steps from "../../components/StartPageComponents/Steps/Steps";

const StartPage = () => {
  return (
    <div className={classes.start}>
      <div className={["ovf-hidden", classes.pos].join(" ")}>
        <Header />
        <First />
        <Second />
        <Three />
        <AccountTypes />
        <Start />
        <Steps />
        <Security />
        <Newsletters />
        <Footer />
      </div>
    </div>
  );
};

export default StartPage;
