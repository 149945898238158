import React, { useEffect, useRef } from "react";
import classes from './AccountTypes.module.scss';
import bg_01 from '../../../assets/img/MainPage/AccountTypes/bg_01.png';
import bg_02 from '../../../assets/img/MainPage/AccountTypes/bg_02.png';
import bg_03 from '../../../assets/img/MainPage/AccountTypes/bg_03.png';
import bg_04 from '../../../assets/img/MainPage/AccountTypes/bg_04.png';
import bg_05 from '../../../assets/img/MainPage/AccountTypes/bg_05.png';
import icon_01 from '../../../assets/img/MainPage/AccountTypes/icon_01.png';
import icon_02 from '../../../assets/img/MainPage/AccountTypes/icon_02.png';
import icon_03 from '../../../assets/img/MainPage/AccountTypes/icon_03.png';
import icon_04 from '../../../assets/img/MainPage/AccountTypes/icon_04.png';
import icon_05 from '../../../assets/img/MainPage/AccountTypes/icon_05.png';
import icon_06 from '../../../assets/img/MainPage/AccountTypes/icon_06.png';
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);


const AccountTypes = () => {
    const { t } = useTranslation();
    const accountTypesRef = useRef(null);
    const cardRef01 = useRef(null);
    const cardRef02 = useRef(null);
    const cardRef03 = useRef(null);
    const cardRef04 = useRef(null);
    const cardRef05 = useRef(null);
    const cardRef06 = useRef(null);
    const cards = [
        {
            icon: icon_01,
            bg: bg_01,
            title: 'account_types_card_01_title',
            subtitle: '$250',
            button: 'account_types_card_01_btn',
            ref: cardRef01,
        },
        {
            icon: icon_06,
            bg: bg_02,
            title: 'account_types_card_06_title',
            subtitle: '$5,000',
            button: 'account_types_card_06_btn',
            ref: cardRef05,
        },
        {
            icon: icon_05,
            bg: bg_05,
            title: 'account_types_card_05_title',
            subtitle: '$25,000',
            button: 'account_types_card_05_btn',
            ref: cardRef05,
        },
        {
            icon: icon_02,
            bg: bg_02,
            title: 'account_types_card_02_title',
            subtitle: '$100,000',
            button: 'account_types_card_02_btn',
            ref: cardRef02,
        },
        {
            icon: icon_03,
            bg: bg_03,
            title: 'account_types_card_03_title',
            subtitle: '$250,000',
            button: 'account_types_card_03_btn',
            ref: cardRef03,
        },
        {
            icon: icon_04,
            bg: bg_04,
            title: 'account_types_card_04_title',
            subtitle: '$500,000',
            button: 'account_types_card_04_btn',
            ref: cardRef04,
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1221) {
            gsap.timeline().fromTo(cardRef01.current, {
                y: 50,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: accountTypesRef.current,
                    end: "+=300px",
                    scrub: 4,
                }
            });
            gsap.timeline().fromTo(cardRef02.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: accountTypesRef.current,
                    end: "+=300px",
                    scrub: 4,
                }
            });
            gsap.timeline().fromTo(cardRef03.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: accountTypesRef.current,
                    end: "+=300px",
                    scrub: 4,
                }
            });
            gsap.timeline().fromTo(cardRef04.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: accountTypesRef.current,
                    end: "+=300px",
                    scrub: 4,
                }
            });
        }
    }, []);
    return (
        <section className={classes.accountTypes}>
            <div className="container">
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('account_types_title')}
                    </h2>
                    <h3 className={[classes.subtitle, 'font-30'].join(' ')}>
                        {t('account_types_subtitle')}
                    </h3>
                    <div ref={accountTypesRef} className={classes.cards}>
                        {cards.map((card, index) =>
                            <div ref={card.ref} key={index} className={classes.card}>
                                <div className={classes.cardPlate}>
                                    <img className={classes.cardIcon} src={card.icon} alt={card.title}/>
                                    <h4 className={classes.cardTitle}>
                                        {t(card.title)}
                                    </h4>
                                    <h5 className={classes.cardSubtitle}>
                                        {t(card.subtitle)}
                                    </h5>
                                    <img className={classes.cardBg} src={card.bg} alt=''/>
                                </div>
                                <div className={classes.cardBtn}>
                                    <Button>{t(card.button)}</Button>
                                </div>
                            </div>   
                        )}
                    </div>
                    <div className={classes.swiperWr}>
                        <Swiper 
                            modules={[Pagination]}
                            className="accountTypesSwiper"
                            spaceBetween={10}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index} className="accountTypesSwiperSlide">
                                    <div ref={card.ref} key={index} className={classes.card}>
                                        <div className={classes.cardPlate}>
                                            <img className={classes.cardIcon} src={card.icon} alt={card.title}/>
                                            <h4 className={classes.cardTitle}>
                                                {t(card.title)}
                                            </h4>
                                            <h5 className={classes.cardSubtitle}>
                                                {t(card.subtitle)}
                                            </h5>
                                            <img className={classes.cardBg} src={card.bg} alt=''/>
                                        </div>
                                        <div className={classes.cardBtn}>
                                            <Button>{t(card.button)}</Button>
                                        </div>
                                    </div>   
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </div>
            </div>
            <img />
        </section>
    );
};

export default AccountTypes;
