import MainPage from '../pages/MainPage/MainPage';
import StartPage from '../pages/StartPage/StartPage';
import AccountsPage from '../pages/AccountsPage/AccountsPage';
import TradingPage from '../pages/TradingPage/TradingPage';
import EducationPage from '../pages/EducationPage/EducationPage';
import AboutPage from '../pages/AboutPage/AboutPage';

export const routes = [
    { path: '*', element: MainPage },
    { path: '/start', element: StartPage },
    { path: '/accounts', element: AccountsPage },
    { path: '/trading', element: TradingPage },
    { path: '/education', element: EducationPage },
    { path: '/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: '/start', text: "header_start" },
    { id: 2, path: '/accounts', text: "header_accounts" },
    { id: 3, path: '/trading', text: "header_trading" },
    { id: 4, path: '/education', text: "header_education" },
    { id: 5, path: '/about', text: "header_about" },
];
