import React from "react";
import classes from "./Banner.module.scss";
import hand from "../../../assets/img/AboutPage/Banner/hand.png";
import hand_b from "../../../assets/img/AboutPage/Banner/hand_b.png";
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Banner = () => {
  const blackTheme = useSelector((state) => state.theme.value);
  const { t } = useTranslation();
  return (
    <section className={classes.banner}>
      <div className="container">
        <div className={classes.content}>
          <h1 className={[classes.title, "font-30"].join(" ")}>
            {t("about_banner_title")}
          </h1>
          <p className={[classes.text, "font-20"].join(" ")}>
            <Trans>{t("about_banner_text")}</Trans>
          </p>
          <div className={classes.btn}>
            <Button>{t("btn_open_account")}</Button>
          </div>
          <img
            className={classes.image}
            src={!blackTheme ? hand : hand_b}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
