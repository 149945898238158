import React from "react";
import classes from './OurTop.module.scss';
import { Trans, useTranslation } from "react-i18next";

const OurTop = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.ourTop}>
                <h2 className={[classes.title, 'font-30'].join(' ')}>
                    {t('our_top_title')}
                </h2>
                <h3 className={[classes.subtitle, 'font-20'].join(' ')}>
                    {t('our_top_subtitle')}
                </h3>
                <div className={classes.content}>
                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h4 className={[classes.colTitle, 'font-20'].join(' ')}>
                                {t('our_top_card_title_01')}
                            </h4>
                            <p className={[classes.colText, 'font-18'].join(' ')}>
                                <Trans>
                                    {t('our_top_card_text_01')}
                                </Trans>
                            </p>
                        </div>
                        <div className={classes.col}>
                            <h4 className={[classes.colTitle, 'font-20'].join(' ')}>
                                {t('our_top_card_title_02')}
                            </h4>
                            <p className={[classes.colText, 'font-18'].join(' ')}>
                                {t('our_top_card_text_02')}
                            </p>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.col}>
                            <h4 className={[classes.colTitle, 'font-20'].join(' ')}>
                                {t('our_top_card_title_03')}
                            </h4>
                            <p className={[classes.colText, 'font-18'].join(' ')}>
                                {t('our_top_card_text_03')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurTop;
