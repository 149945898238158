import React from "react";
import { Trans } from "react-i18next";
import classes from './OpeningHour.module.scss';
import { useTranslation } from "react-i18next";

const rows = [
    [
        'Canada',
        'Toronto Stock Exchange',
        'Shares',
        '15:30-22:00',
    ],
    [
        '',
        'Toronto Ventures Exchange',
        'Shares',
        '15:30-22:00',
    ],
    [
        'United States',
        'Chicago Board Options Exchange (CBOE)',
        'Options',
        '24:00-23:00',
    ],
    [
        '',
        'Chicago Mercantile Exchange (CME)',
        'Futures<br/>Options',
        '24:00-23:00<br/>24:00-23:00',
    ],
    [
        '',
        'NASDAQ',
        'Shares',
        '15:30-22:00',
    ],
    [
        '',
        'New York Mercantile Exchange (NYMEX)',
        'Futures',
        '24:00-23:00',
    ],
    [
        '',
        'New York Stock Exchange',
        'Shares',
        '15:30-22:00',
    ],
    [
        '',
        'New York Stock Exchange MKT (AMEX)',
        'Shares',
        '15:30-22:00',
    ],
    [
        '',
        'New York Stock Exchange Arca',
        'Shares',
        '15:30-22:00',
    ],
    [
        'World',
        'Tradegate',
        'Shares',
        '08:00-22:00',
    ],
    [
        '',
        'SAME',
        'Futures<br/>Options',
        '08:00-20:00<br/>09:00-18:00',
    ],
    [
        '',
        'CBOT',
        'Futures',
        '24:00-23:00',
    ],
    [
        '',
        'COMEX',
        'Obligations',
        '24:00-23:00',
    ],
];

const OpeningHour = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.openingHour}>
            <div className="container">
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('opening_hours_title')}
                    </h2>
                    <h3 className={[classes.subtitle, 'font-30'].join(' ')}>
                        {t('opening_hours_subtitle')}
                    </h3>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        {t('opening_hours_text')}
                    </p>
                </div>
            </div>
            <div className={[classes.container, "container"].join(' ')}>
                <div className={classes.tableWr}>
                    <table>
                        <tbody>
                            <tr className={classes.tableHeader}>
                                <td>{t('opening_hours_table_col_01')}</td>
                                <td>{t('opening_hours_table_col_02')}</td>
                                <td>{t('opening_hours_table_col_03')}</td>
                                <td>
                                    <Trans>
                                        {t('opening_hours_table_col_04')}
                                    </Trans>
                                </td>
                            </tr>
                            {rows.map((row, index) =>
                                <tr key={index}>
                                    {row.map((col, colIndex) =>
                                        <td key={colIndex}>
                                            <Trans>
                                                {col}
                                            </Trans>
                                        </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default OpeningHour;
