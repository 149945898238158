import React from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/StartPage/2/1.png";
import img2 from "../../../assets/img/StartPage/2/2.png";
import img3 from "../../../assets/img/StartPage/2/3.png";
import { useTranslation } from "react-i18next";

const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.second}>
      <div className={classes.secondBody}>
        <div className="container">
          <div className={[classes.secondTitle, "font-40"].join(" ")}>
            {t('everything_title')}
          </div>
          <div className={[classes.secondSubtitle, "font-30"].join(" ")}>
            {t('everything_subtitle')}
          </div>
          <div className={[classes.secondText, "font-20"].join(" ")}>
            {t('everything_text')}
          </div>
          <div className={classes.secondRow}>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnItem}>
                <div className={classes.secondRowColumnItemImg}>
                  <img src={img1} alt="" />
                </div>
                <div
                  className={[classes.secondRowColumnItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('everything_card_title_01')}
                </div>
                <div
                  className={[
                    classes.secondRowColumnItemSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t('everything_card_text_01')}
                </div>
              </div>
            </div>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnItem}>
                <div className={classes.secondRowColumnItemImg}>
                  <img src={img2} alt="" />
                </div>
                <div
                  className={[classes.secondRowColumnItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('everything_card_title_02')}
                </div>
                <div
                  className={[
                    classes.secondRowColumnItemSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t('everything_card_text_02')}
                </div>
              </div>
            </div>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnItem}>
                <div className={classes.secondRowColumnItemImg}>
                  <img src={img3} alt="" />
                </div>
                <div
                  className={[classes.secondRowColumnItemTitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('everything_card_title_03')}
                </div>
                <div
                  className={[
                    classes.secondRowColumnItemSubtitle,
                    "font-20",
                  ].join(" ")}
                >
                  {t('everything_card_text_03')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
