import React, { useEffect, useRef, useState } from "react";
import classes from "./Newsletters.module.scss";
import globe from "../../../assets/img/MainPage/Newsletters/globe.png";
import globe_b from "../../../assets/img/MainPage/Newsletters/globe_b.png";
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
gsap.registerPlugin(ScrollTrigger);

const Newsletters = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const newslettersRef = useRef(null);
  const globeRef = useRef(null);
  const blackTheme = useSelector((state) => state.theme.value);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1221) {
      gsap.timeline().fromTo(
        globeRef.current,
        {
          y: -50,
        },
        {
          y: 50,
          scrollTrigger: {
            trigger: newslettersRef.current,
            end: "+=1000px",
            scrub: 5,
          },
        }
      );
    }
  }, []);
  return (
    <div className={[classes.back].join(" ")}>
      <section className={["container"].join(" ")}>
        <div ref={newslettersRef} className={classes.newsletters}>
          <div className={classes.content}>
            <h2 className={[classes.title, "font-40"].join(" ")}>
              {t("newsletters_title")}
            </h2>
            <h3 className={[classes.subtitle, "font-30"].join(" ")}>
              {t("newsletters_subtitle")}
            </h3>
            <div className={[classes.text, "font-20"].join(" ")}>
              <p>{t("newsletters_text_01")}</p>
              <p>{t("newsletters_text_02")}</p>
            </div>
            <div className={classes.form}>
              <input
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                type="email"
                placeholder={t("newsletters_your_email")}
              />
              <div className={classes.btn}>
                <Button>{t("btn_subscribe")}</Button>
              </div>
            </div>
          </div>
          <img
            ref={globeRef}
            className={classes.globe}
            src={!blackTheme ? globe : globe_b}
            alt="Newsletters: the latest market news by e-mail"
          />
        </div>
      </section>
    </div>
  );
};

export default Newsletters;
