import React from "react";
import classes from "./Security.module.scss";
import { useTranslation } from "react-i18next";

const Security = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.security}>
      <div className={classes.securityBody}>
        <div className="container">
          <div className={classes.security}>
            <h2 className={[classes.securityTitle, "font-40"].join(" ")}>
              {t('security_title')}
            </h2>
            <div className={classes.securityRow}>
              <div className={classes.securityRowLeft}>
                <h3
                  className={[classes.securityRowLeftTitle, "font-30"].join(
                    " "
                  )}
                >
                  {t('security_subtitle_02')}
                </h3>
                <div
                  className={[classes.securityRowLeftSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('security_text_03')}
                </div>
                <div
                  className={[classes.securityRowLeftSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('security_text_04')}
                </div>
              </div>
              <div className={classes.securityRowRight}>
                <div
                  className={[classes.securityRowRightTitle, "font-30"].join(
                    " "
                  )}
                >
                  {t('security_subtitle_01')}
                </div>
                <div
                  className={[classes.securityRowRightSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('security_text_01')}
                </div>
                <div
                  className={[classes.securityRowRightSubtitle, "font-20"].join(
                    " "
                  )}
                >
                  {t('security_text_02')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
