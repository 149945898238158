import React from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from '../../components/AccountsPage/Banner/Banner';
import classes from './AccountsPage.module.scss';
import AccountTypes from "../../components/AccountsPage/AccountTypes/AccountTypes";
import OpeningHour from "../../components/AccountsPage/OpeningHour/OpeningHour";
import InvestAt from "../../components/AccountsPage/InvestAt/InvestAt";
import InvestArround from "../../components/AccountsPage/InvestArround/InvestArround";
import Newsletters from "../../components/MainPage/Newsletters/Newsletters";

const AccountsPage = () => {
    return (
        <div className="ovf-hidden">
            <div className={classes.pageBg}>
                <Header />
                <Banner />
                <AccountTypes />
                <InvestAt />
                <OpeningHour />
                <InvestArround />
                <Newsletters />
                <Footer />
            </div>
        </div>
    );
};

export default AccountsPage;
