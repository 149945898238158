import React from "react";
import classes from "./Button.module.scss";

const Button = (props) => {
  return (
    <a href="https://user.styx-trade.co/signup" className={classes.button}>
      <span>{props.children}</span>
    </a>
  );
};

export default Button;
