import img1 from "./assets/img/MainPage/Banner/bg.png"
import img2 from "./assets/img/MainPage/Banner/bg_b.png"
import img3 from "./assets/img/MainPage/Banner/whale.png"
import img4 from "./assets/img/MainPage/Banner/whale_b.png"
import img5 from "./assets/img/MainPage/bg.png"
import img6 from "./assets/img/StartPage/1/1.png"
import img7 from "./assets/img/StartPage/1/1_b.png"
import img8 from "./assets/img/StartPage/bg.png"
import img9 from "./assets/img/StartPage/bg_b.png"
import img10 from "./assets/img/AccountsPage/banner_bear_b.png"
import img11 from "./assets/img/AccountsPage/banner_bear.png"
import img12 from "./assets/img/AccountsPage/page_bg_b.png"
import img13 from "./assets/img/AccountsPage/page_bg.png"
import img14 from "./assets/img/Trading/1/bg.png"
import img15 from "./assets/img/Trading/1/1.png"
import img16 from "./assets/img/EducationPage/edu_banner.png"
import img17 from "./assets/img/EducationPage/edu_bg_b.png"
import img18 from "./assets/img/EducationPage/edu_bg.png"
import img19 from "./assets/img/AboutPage/about_bg_b.png"
import img20 from "./assets/img/AboutPage/about_bg.png"
import img21 from "./assets/img/AboutPage/Banner/bg_b.png"
import img22 from "./assets/img/AboutPage/Banner/bg.png"
import img23 from "./assets/img/AboutPage/Banner/hand_b.png"
import img24 from "./assets/img/AboutPage/Banner/hand.png"


export const IMAGES = [
    {
        id: 0,
        url:
            img1
    },
    {
        id: 1,
        url:
            img2
    },
    {
        id: 2,
        url:
            img3
    },
    {
        id: 3,
        url:
            img4
    },
    {
        id: 4,
        url:
            img5
    },
    {
        id: 5,
        url:
            img6
    },
    {
        id: 6,
        url:
            img7
    },
    {
        id: 7,
        url:
            img8
    }, {
        id: 8,
        url:
            img9
    },
    {
        id: 9,
        url:
            img10
    },
    {
        id: 10,
        url:
            img11
    },
    {
        id: 11,
        url:
            img12
    }, {
        id: 12,
        url:
            img13
    },
    {
        id: 13,
        url:
            img14
    },
    {
        id: 14,
        url:
            img15
    },
    {
        id: 15,
        url:
            img16
    }, {
        id: 16,
        url:
            img17
    },
    {
        id: 17,
        url:
            img18
    },
    {
        id: 18,
        url:
            img19
    },
    {
        id: 19,
        url:
            img20
    }, {
        id: 20,
        url:
            img21
    },
    {
        id: 21,
        url:
            img22
    },
    {
        id: 22,
        url:
            img23
    },
    {
        id: 23,
        url:
            img24
    }

]
