import React, { useRef, useEffect } from "react";
import classes from "./Banner.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import banner_bear from "../../../assets/img/AccountsPage/banner_bear.png";
import banner_bear_b from "../../../assets/img/AccountsPage/banner_bear_b.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
  const blackTheme = useSelector((state) => state.theme.value);
  const { t } = useTranslation();
  const titleRef = useRef(null);
  const textRef = useRef(null);
  const btnRef = useRef(null);
  const bannerBearRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1221) {
      gsap.fromTo(
        titleRef.current,
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        textRef.current,
        {
          opacity: 0,
          y: 200,
        },
        {
          opacity: 1,
          y: 0,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        btnRef.current,
        {
          opacity: 0,
          y: 300,
        },
        {
          opacity: 1,
          y: 0,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        bannerBearRef.current,
        {
          opacity: 0,
          x: 200,
        },
        {
          opacity: 1,
          x: 0,
          delay: 0.5,
          duration: 1,
        }
      );

      if (t("lang") === "ar") {
        gsap.fromTo(
          bannerBearRef.current,
          {
            scaleX: -1,
            opacity: 0,
            x: -200,
          },
          {
            opacity: 1,
            x: 0,
            delay: 0.5,
            duration: 1,
          }
        );
      } else {
        gsap.fromTo(
          bannerBearRef.current,
          {
            scaleX: 1,
            opacity: 0,
            x: 200,
          },
          {
            opacity: 1,
            x: 0,
            delay: 0.5,
            duration: 1,
          }
        );
      }
    }
  }, [t("lang")]);
  return (
    <section className={classes.banner}>
      <div className="container">
        <h1 ref={titleRef} className={[classes.title, "font-45"].join(" ")}>
          {t("accounts_banner_title")}
        </h1>
        <h3 ref={textRef} className={[classes.text, "font-25"].join(" ")}>
          {t("accounts_banner_text")}
        </h3>
        <div ref={btnRef} className={classes.btn}>
          <Button>{t("btn_open_account")}</Button>
        </div>
      </div>
      <img
        ref={bannerBearRef}
        className={classes.banner_bear}
        src={!blackTheme ? banner_bear : banner_bear_b}
        alt="Top Diversity of Accounts and Instruments at STYXTrade Group"
      />
    </section>
  );
};

export default Banner;
