import React, { useEffect, useRef } from "react";
import classes from "./Awards.module.scss";
import award_01 from "../../../assets/img/MainPage/Awards/award_01.png";
import award_02 from "../../../assets/img/MainPage/Awards/award_02.png";
import award_03 from "../../../assets/img/MainPage/Awards/award_03.png";
import coin_01 from "../../../assets/img/MainPage/Awards/coin_01.png";
import coin_02 from "../../../assets/img/MainPage/Awards/coin_02.png";
import coin_03 from "../../../assets/img/MainPage/Awards/coin_03.png";
import coin_04 from "../../../assets/img/MainPage/Awards/coin_04.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Awards = () => {
  const { t } = useTranslation();
  const awardsRef = useRef(null);
  const coinRef01 = useRef(null);
  const coinRef02 = useRef(null);
  const coinRef03 = useRef(null);
  const coinRef04 = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1221) {
      gsap.timeline().fromTo(
        coinRef01.current,
        {
          y: 50,
        },
        {
          y: 0,
          ease: 4,
          scrollTrigger: {
            trigger: awardsRef.current,
            start: "top bottom",
            end: "+=500px",
            scrub: 5,
          },
        }
      );
    }
    if (document.documentElement.clientWidth > 1221) {
      gsap.timeline().fromTo(
        coinRef02.current,
        {
          y: 150,
          x: -100,
        },
        {
          y: 0,
          x: 0,
          ease: 4,
          scrollTrigger: {
            trigger: awardsRef.current,
            start: "top bottom",
            end: "+=500px",
            scrub: 5,
          },
        }
      );
    }
    if (document.documentElement.clientWidth > 1221) {
      gsap.timeline().fromTo(
        coinRef03.current,
        {
          y: 200,
          x: 150,
        },
        {
          y: 0,
          x: 0,
          scrollTrigger: {
            trigger: awardsRef.current,
            start: "top bottom",
            end: "+=500px",
            scrub: 5,
          },
        }
      );
    }
    if (document.documentElement.clientWidth > 1221) {
      gsap.timeline().fromTo(
        coinRef04.current,
        {
          y: 300,
          x: 50,
        },
        {
          y: 0,
          x: 0,
          scrollTrigger: {
            trigger: awardsRef.current,
            start: "top bottom",
            end: "+=500px",
            scrub: 5,
          },
        }
      );
    }
  }, []);
  return (
    <section ref={awardsRef} className="container">
      <div className={classes.awards}>
        <h2 className={[classes.title, "font-40"].join(" ")}>
          {t("platform_awards_title")}
        </h2>
        <h3 className={[classes.subtitle, "font-30"].join(" ")}>
          {t("platform_awards_subtitle")}
        </h3>
        <p className={[classes.text, "font-20"].join(" ")}>
          {t("platform_awards_text")}
        </p>
        <div className={classes.cards}>
          <img className={classes.award_01} src={award_01} alt="" />
          <img className={classes.award_02} src={award_02} alt="" />
          <img className={classes.award_03} src={award_03} alt="" />
        </div>
        <img
          ref={coinRef01}
          className={[classes.coin, classes.coin_01].join(" ")}
          src={coin_01}
          alt=""
        />
        <img
          ref={coinRef02}
          className={[classes.coin, classes.coin_02].join(" ")}
          src={coin_02}
          alt=""
        />
        <img
          ref={coinRef03}
          className={[classes.coin, classes.coin_03].join(" ")}
          src={coin_03}
          alt=""
        />
        <img
          ref={coinRef04}
          className={[classes.coin, classes.coin_04].join(" ")}
          src={coin_04}
          alt=""
        />
      </div>
    </section>
  );
};

export default Awards;
