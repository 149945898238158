import React from "react";
import classes from "./Banner.module.scss";
import edu_banner from "../../../assets/img/EducationPage/edu_banner.png";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <section className="container">
      <div className={classes.banner}>
        <div className={classes.content}>
          <h1 className={[classes.title, "font-30"].join(" ")}>
            {t("education_banner_title")}
          </h1>
          <p className={[classes.text, "font-18"].join(" ")}>
            {t("education_banner_text")}
          </p>
          <h2 className={[classes.subtitle, "font-30"].join(" ")}>
            {t("the_investor_title")}
          </h2>
          <p className={[classes.text, "font-18"].join(" ")}>
            {t("the_investor_text")}
          </p>
        </div>
        <img
          className={classes.image}
          src={edu_banner}
          alt="Boost your investments with STYXTrade Group"
        />
      </div>
    </section>
  );
};

export default Banner;
