import React, { useState } from "react";
import classes from './TopFacts.module.scss';
import facts_image from '../../../assets/img/AboutPage/top_facts.png';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        id: 1,
        title: 'top_facts_card_title_01',
        text: 'top_facts_card_text_01',
    },
    {
        id: 2,
        title: 'top_facts_card_title_02',
        text: 'top_facts_card_text_02',
    },
    {
        id: 3,
        title: 'top_facts_card_title_03',
        text: 'top_facts_card_text_03',
    },
    {
        id: 4,
        title: 'top_facts_card_title_04',
        text: 'top_facts_card_text_04',
    },
    {
        id: 5,
        title: 'top_facts_card_title_05',
        text: 'top_facts_card_text_05',
    },
    {
        id: 6,
        title: 'top_facts_card_title_06',
        text: 'top_facts_card_text_06',
    },
];

const TopFacts = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(1);
    return (
        <section className="container">
            <div className={classes.topFacts}>
                <h2 className={[classes.title, 'font-30'].join(' ')}>
                    {t('top_facts_title')}
                </h2>
                <div className={classes.content}>
                    <div className={classes.tabs}>
                        {tabs.map((tab) =>
                            <div 
                                key={tab.id} 
                                className={`${classes.tab} ${tab.id === currentTab && classes.current}`}
                                onClick={() => setCurrentTab(tab.id)}    
                            >
                                <h3 className={[classes.tabTitle, 'font-20'].join(' ')}>
                                    0{tab.id}. {t(tab.title)}
                                </h3>
                                <p className={`${classes.tabText} font-18`}>
                                    {t(tab.text)}
                                </p>
                            </div>
                        )}
                    </div>
                    <img className={classes.image} src={facts_image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default TopFacts;
