import React from "react";
import classes from "./Footer.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <div className={classes.footer}>
      <div className="container">
        <div className={classes.content}>
          <div className={classes.info}>
            <div className={classes.row}>
              <h5 className={classes.rowTitle}>{t("footer_company_info")}</h5>
              <p className={classes.rowText}>
                <Trans>{t("footer_company_info_text")}</Trans>
              </p>
            </div>
            <div className={classes.row}>
              <h5 className={classes.rowTitle}>{t("footer_risk_warning")}</h5>
              <p className={classes.rowText}>{t("footer_risk_warning_text")}</p>
            </div>
            <div className={classes.row}>
              <h5 className={classes.rowTitle}>{t("footer_regional_restr")}</h5>
              <p className={classes.rowText}>
                {t("footer_regional_restr_text_01")}
              </p>
            </div>
            <div className={classes.row}>
              <p className={classes.rowText}>
                {t("footer_regional_restr_text_02")}
              </p>
            </div>
            <div className={classes.row}>
              <p className={classes.rowText}>{t("footer_rights")}</p>
            </div>
          </div>
          <div className={classes.docs}>
            <div>
              <a href="pdf/terms.pdf">{t("footer_terms_conditions")}</a>
            </div>
            <div>
              <a href="pdf/privacy_policy.pdf">{t("footer_privacy_policy")}</a>
            </div>
            <div>
              <a href="pdf/aml_policy.pdf">{t("footer_aml_policy")}</a>
            </div>
            <div>
              <a href="pdf/money_back_policy.pdf">{t("footer_money_back_policy")}</a>
            </div>
          </div>
          <div className={classes.about}>
            <p>{t("footer_can_text")}</p>
            <div>
              <p>{t("footer_contacts")}</p>
              <a href="tel:+441519479678">+441519479678</a>
              <a href="mailto:support@styx-trade.info">support@styx-trade.info</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
