import React from "react";
import classes from "./How.module.scss";
import img1 from "../../../assets/img/Trading/4/1.png";
import img2 from "../../../assets/img/Trading/4/2.png";
import img3 from "../../../assets/img/Trading/4/3.png";
import img4 from "../../../assets/img/Trading/4/4.png";
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";

const How = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.how}>
      <div className={classes.howBody}>
        <div className="container">
          <h2 className={[classes.howTitle, "font-40"].join(" ")}>
            {t('how_to_become_title')}
          </h2>
          <div className={classes.howTop}>
            <div className={classes.howTopColumn}>
              <div className={classes.howTopColumnImg}>
                <img src={img1} alt="" />
              </div>
              <p className={[classes.howTopColumnText, "font-20"].join(" ")}>
                <Trans>
                  {t('how_to_become_li_01')}
                </Trans>
              </p>
            </div>
            <div className={classes.howTopColumn}>
              <div className={classes.howTopColumnImg}>
                <img src={img2} alt="" />
              </div>
              <p className={[classes.howTopColumnText, "font-20"].join(" ")}>
                <Trans>
                  {t('how_to_become_li_02')}
                </Trans>
              </p>
            </div>
            <div className={classes.howTopColumn}>
              <div className={classes.howTopColumnImg}>
                <img src={img3} alt="" />
              </div>
              <p className={[classes.howTopColumnText, "font-20"].join(" ")}>
                <Trans>
                  {t('how_to_become_li_03')}
                </Trans>
              </p>
            </div>
            <div className={classes.howTopColumn}>
              <div className={classes.howTopColumnImg}>
                <img src={img4} alt="" />
              </div>
              <p className={[classes.howTopColumnText, "font-20"].join(" ")}>
                <Trans>
                  {t('how_to_become_li_04')}
                </Trans>
              </p>
            </div>
          </div>
          <div className={classes.howTableWraper}>
            <div className={classes.howTable}>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_01_col_01')}
                </div>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_01_col_02')}
                </div>
              </div>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_02_col_01')}
                </div>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_02_col_02')}
                </div>
              </div>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_03_col_01')}
                </div>
                <div className={classes.howTableContentColumn}>$250</div>
              </div>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_04_col_01')}
                </div>
                <div className={classes.howTableContentColumn}>USD</div>
              </div>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>{t('how_to_become_row_05_col_01')}</div>
                <div className={classes.howTableContentColumn}>1:500</div>
              </div>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_06_col_01')}
                </div>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_06_col_02')}
                </div>
              </div>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_07_col_01')}
                </div>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_07_col_02')}
                </div>
              </div>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_08_col_01')}
                </div>
                <div className={classes.howTableContentColumn}>{t('how_to_become_row_08_col_02')}</div>
              </div>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_09_col_01')}
                </div>
                <div className={classes.howTableContentColumn}>20%</div>
              </div>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_10_col_01')}
                </div>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_10_col_02')}
                </div>
              </div>
              <div className={classes.howTableContent}>
                <div className={classes.howTableContentColumn}>
                  {t('how_to_become_row_11_col_01')}
                </div>
                <div className={classes.howTableContentColumn}>{t('how_to_become_row_11_col_02')}</div>
              </div>
            </div>
          </div>
          <div className={classes.howBtn}>
            <Button>{t('btn_open_account')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default How;
