import React, { useState } from "react";
import classes from './ContactUs.module.scss';
import { Trans, useTranslation } from "react-i18next";

const ContactUs = () => {
    const { t } = useTranslation();
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ message, setMessage ] = useState('');
    const submit = () => {
        if (name.length < 1) {
            alert('Invalid name');
            return;
        }
        const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!expression.test(String(email.toLocaleLowerCase()))) {
            alert('Invalid email');
            return;
        }
        if (phone.length < 1) {
            alert('Invalid phone');
            return;
        }
        if (message.length < 1) {
            alert('Empty message');
            return;
        }
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setTimeout(() => {
            alert('Sent!');
        }, 500);
    };
    return (
        <section className="container">
            <div className={classes.contactUs}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-30'].join(' ')}>
                        {t('contact_us_title')}
                    </h2>
                    <p className={[classes.text, 'font-18'].join(' ')}>
                        <Trans>
                            {t('contact_us_subtitle')}
                        </Trans>
                    </p>
                    <div className={classes.form}>
                        <input 
                            type="text"
                            placeholder={t('contact_us_name')}
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            onKeyPress={(event) => {
                                if (/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                            }}
                        />
                        <input 
                            type="email"
                            placeholder={t('contact_us_email')}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <input 
                            type="tel"
                            placeholder={t('contact_us_phone')}
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                            }}
                        />
                        <textarea 
                            placeholder={t('contact_us_message')}
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                        />
                        <div className={classes.btn}>
                            <div className={classes.button} onClick={submit}>
                                <span>{t('contact_us_btn')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
