import React from "react";
import classes from './EducationPage.module.scss';
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/EducationPage/Banner/Banner";
import WhatType from "../../components/EducationPage/WhatType/WhatType";
import ComplexFinancial from "../../components/EducationPage/ComplexFinancial/ComplexFinancial";
import WhatIs from "../../components/EducationPage/WhatIs/WhatIs";
import Newsletters from "../../components/MainPage/Newsletters/Newsletters";
import TypesOf from "../../components/EducationPage/TypesOf/TypesOf";
import TechnicalAnalysis from "../../components/EducationPage/TechnicalAnalysis/TechnicalAnalysis";

const EducationPage = () => {
    return (
        <div className="ovf-hidden">
            <div className={classes.pageBg}>
                <Header />
                <Banner />
                <WhatType />
                <ComplexFinancial />
                <TypesOf />
                <TechnicalAnalysis />
                <WhatIs />
                <Newsletters />
                <Footer />
            </div>
        </div>
    );
};

export default EducationPage;
