import { createSlice } from '@reduxjs/toolkit'

export const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        value: false,
    },
    reducers: {
        white: (state) => {
            // Redux Toolkit позволяет нам писать "мутабельную" логику в reducer'ах.
            // Это не изменяет состояние(state) напрямую, потому что внутри используется библиотека Immer,
            // которая следит за изменениями в "черновом state" и создает новое 
            // неизменное состояние на основе этих изменений
            state.value = false
        },
        black: (state) => {
            state.value = true
        },

    },
})

// Функция действия генерируется на каждую функцию релюсера(reducer), определённую в createSlice
export const { white, black } = themeSlice.actions

export default themeSlice.reducer