import React, { useEffect, useRef } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./InvestArround.module.scss";
import invest_arround from "../../../assets/img/AccountsPage/invest_arround.png";
import invest_arround_b from "../../../assets/img/AccountsPage/invest_arround_b.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
gsap.registerPlugin(ScrollTrigger);

const InvestArround = () => {
  const blackTheme = useSelector((state) => state.theme.value);
  const { t } = useTranslation();
  const investArroundRef = useRef(null);
  const imageRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1221) {
      gsap.timeline().fromTo(
        imageRef.current,
        {
          y: -50,
        },
        {
          y: 50,
          scrollTrigger: {
            trigger: investArroundRef.current,
            end: "+=1000px",
            scrub: 5,
          },
        }
      );
    }
  }, []);
  return (
    <section className="container">
      <div ref={investArroundRef} className={classes.investArround}>
        <div className={classes.content}>
          <h2 className={[classes.title, "font-40"].join(" ")}>
            {t("invest_arround_title")}
          </h2>
          <div className={[classes.text, "font-20"].join(" ")}>
            <p>{t("invest_arround_text_01")}</p>
            <p>{t("invest_arround_text_02")}</p>
            <p>{t("invest_arround_text_03")}</p>
          </div>
          <div className={classes.btn}>
            <Button>{t("btn_open_account")}</Button>
          </div>
        </div>
        <img
          ref={imageRef}
          className={classes.image}
          src={!blackTheme ? invest_arround : invest_arround_b}
          alt=""
        />
      </div>
    </section>
  );
};

export default InvestArround;
