import React from "react";
import classes from './TechnicalAnalysis.module.scss';
import image_01 from '../../../assets/img/EducationPage/tech_01.png';
import image_02 from '../../../assets/img/EducationPage/tech_02.png';
import { useTranslation } from "react-i18next";

const TechnicalAnalysis = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.technicalAnalysis}>
                <div className={classes.row}>
                    <div className={classes.col}>
                        <h2 className={[classes.title, 'font-30'].join(' ')}>
                            {t('technical_analysis_title')}
                        </h2>
                        <div className={[classes.text, 'font-18'].join(' ')}>
                            <p>{t('technical_analysis_text_01')}</p>
                            <p>{t('technical_analysis_text_02')}</p>
                            <p>{t('technical_analysis_text_03')}</p>
                            <p>{t('technical_analysis_text_04')}</p>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <img src={image_01} alt=''/>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.col}>
                        <img src={image_02} alt=''/>
                    </div>
                    <div className={classes.col}>
                        <h2 className={[classes.title, 'font-30'].join(' ')}>
                            {t('what_is_title')}
                        </h2>
                        <div className={[classes.text, 'font-18'].join(' ')}>
                            <p>{t('what_is_text_01')}</p>
                            <p>{t('what_is_text_02')}</p>
                            <p>{t('what_is_text_03')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TechnicalAnalysis;
