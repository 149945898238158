import React from "react";
import classes from "./Group.module.scss";
import img1 from "../../../assets/img/Trading/5/1.png";
import img2 from "../../../assets/img/Trading/5/2.png";
import img3 from "../../../assets/img/Trading/5/3.png";
import img4 from "../../../assets/img/Trading/5/4.png";
import img5 from "../../../assets/img/Trading/5/5.png";
import img6 from "../../../assets/img/Trading/5/6.png";
import img7 from "../../../assets/img/Trading/5/7.png";
import img8 from "../../../assets/img/Trading/5/8.png";

import img1_b from "../../../assets/img/Trading/5/1_b.png";
import img2_b from "../../../assets/img/Trading/5/2_b.png";
import img3_b from "../../../assets/img/Trading/5/3_b.png";
import img4_b from "../../../assets/img/Trading/5/4_b.png";
import img5_b from "../../../assets/img/Trading/5/5_b.png";
import img6_b from "../../../assets/img/Trading/5/6_b.png";
import img7_b from "../../../assets/img/Trading/5/7_b.png";
import img8_b from "../../../assets/img/Trading/5/8_b.png";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Group = () => {
  const { t } = useTranslation();
  const blackTheme = useSelector((state) => state.theme.value);
  return (
    <div className={classes.group}>
      <div className={classes.groupBody}>
        <div className="container">
          <h2 className={[classes.groupTitle, "font-40"].join(" ")}>
            {t("platform_metrics_title")}
          </h2>
          <div className={classes.groupRowWrap}>
            <div className={classes.groupRow}>
              <div className={classes.groupRowColumn}>
                <div className={classes.groupRowColumnImg}>
                  <img src={!blackTheme ? img1 : img1_b} alt="" />
                </div>
                <h3 className={classes.groupRowColumnTitle}>
                  {t("platform_metrics_card_title_01")}
                </h3>
                <p className={classes.groupRowColumnText}>
                  <Trans>{t("platform_metrics_card_text_01")}</Trans>
                </p>
              </div>
              <div className={classes.groupRowColumn}>
                <div className={classes.groupRowColumnImg}>
                  <img src={!blackTheme ? img2 : img2_b} alt="" />
                </div>
                <h3 className={classes.groupRowColumnTitle}>
                  {t("platform_metrics_card_title_02")}
                </h3>
                <p className={classes.groupRowColumnText}>
                  <Trans>{t("platform_metrics_card_text_02")}</Trans>
                </p>
              </div>
              <div className={classes.groupRowColumn}>
                <div className={classes.groupRowColumnImg}>
                  <img src={!blackTheme ? img3 : img3_b} alt="" />
                </div>
                <h3 className={classes.groupRowColumnTitle}>
                  {t("platform_metrics_card_title_03")}
                </h3>
                <p className={classes.groupRowColumnText}>
                  <Trans>{t("platform_metrics_card_text_03")}</Trans>
                </p>
              </div>
              <div className={classes.groupRowColumn}>
                <div className={classes.groupRowColumnImg}>
                  <img src={!blackTheme ? img4 : img4_b} alt="" />
                </div>
                <h3 className={classes.groupRowColumnTitle}>
                  {t("platform_metrics_card_title_04")}
                </h3>
                <p className={classes.groupRowColumnText}>
                  <Trans>{t("platform_metrics_card_text_04")}</Trans>
                </p>
              </div>
              <div className={classes.groupRowColumn}>
                <div className={classes.groupRowColumnImg}>
                  <img src={!blackTheme ? img5 : img5_b} alt="" />
                </div>
                <h3 className={classes.groupRowColumnTitle}>
                  {t("platform_metrics_card_title_05")}
                </h3>
                <p className={classes.groupRowColumnText}>
                  <Trans>{t("platform_metrics_card_text_05")}</Trans>
                </p>
              </div>
              <div className={classes.groupRowColumn}>
                <div className={classes.groupRowColumnImg}>
                  <img src={!blackTheme ? img6 : img6_b} alt="" />
                </div>
                <h3 className={classes.groupRowColumnTitle}>
                  {t("platform_metrics_card_title_06")}
                </h3>
                <p className={classes.groupRowColumnText}>
                  <Trans>{t("platform_metrics_card_text_06")}</Trans>
                </p>
              </div>
              <div className={classes.groupRowColumn}>
                <div className={classes.groupRowColumnImg}>
                  <img src={!blackTheme ? img7 : img7_b} alt="" />
                </div>
                <h3 className={classes.groupRowColumnTitle}>
                  {t("platform_metrics_card_title_07")}
                </h3>
                <p className={classes.groupRowColumnText}>
                  <Trans>{t("platform_metrics_card_text_07")}</Trans>
                </p>
              </div>
              <div className={classes.groupRowColumn}>
                <div className={classes.groupRowColumnImg}>
                  <img src={!blackTheme ? img8 : img8_b} alt="" />
                </div>
                <h3 className={classes.groupRowColumnTitle}>
                  {t("platform_metrics_card_title_08")}
                </h3>
                <p className={classes.groupRowColumnText}>
                  <Trans>{t("platform_metrics_card_text_08")}</Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Group;
