import React from "react";
import classes from './WhatIs.module.scss';
import { Trans, useTranslation } from "react-i18next";

const WhatIs = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.complexFinancial}>
                <div className={classes.headers}>
                    <h2 className={[classes.title, 'font-30'].join(' ')}>
                        {t('what_is_your_title')}
                    </h2>
                    <p className={[classes.text, 'font-18'].join(' ')}>
                        {t('what_is_your_text')}
                    </p>
                </div>
                <div className={classes.content}>
                    <div className={classes.block}>
                        <h3 className={[classes.subtitle, 'font-20'].join(' ')}>
                            {t('what_is_your_subtitle_01')}
                        </h3>
                        <p className="font-18">
                            {t('what_is_your_text_01')}
                        </p>
                    </div>
                    <div className={classes.block}>
                        <h3 className={[classes.subtitle, 'font-20'].join(' ')}>
                            {t('what_is_your_subtitle_02')}
                        </h3>
                        <p className="font-18">
                            {t('what_is_your_text_02')}
                        </p>
                    </div>
                    <div className={classes.block}>
                        <h3 className={[classes.subtitle, 'font-20'].join(' ')}>
                            {t('what_is_your_subtitle_03')}
                        </h3>
                        <p className="font-18">
                            <Trans>
                                {t('what_is_your_text_03')}
                            </Trans>
                        </p>
                    </div>
                    <div className={classes.block}>
                        <h3 className={[classes.subtitle, 'font-20'].join(' ')}>
                            {t('what_is_your_subtitle_04')}
                        </h3>
                        <p className="font-18">
                            {t('what_is_your_text_04')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhatIs;
