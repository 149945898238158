import React from "react";
import classes from "./Three.module.scss";
import imgYes from "../../../assets/img/StartPage/3/1.svg";
import imgNo from "../../../assets/img/StartPage/3/2.svg";
import { useTranslation } from "react-i18next";

const Three = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.three}>
      <div className={classes.threeBody}>
        <div className={["container", classes.cont].join(" ")}>
          <div className={[classes.threeTitle, "font-40"].join(" ")}>
            {t('products_title')}
          </div>
          <div className={[classes.threeSubtitle, "font-30"].join(" ")}>
            {t('products_subtitle')}
          </div>
          <div className={classes.threeTableWrap}>
            <div className={classes.threeTable}>
              <div className={classes.threeTableTop}>
                <div className={classes.threeTableTopColumn}>{t('products_col_01')}</div>
                <div className={classes.threeTableTopColumn}>{t('products_col_02')}</div>
                <div className={classes.threeTableTopColumn}>{t('products_col_03')}</div>
                <div className={classes.threeTableTopColumn}>{t('products_col_04')}</div>
                <div className={classes.threeTableTopColumn}>{t('products_col_05')}</div>
                <div className={classes.threeTableTopColumn}>{t('products_col_06')}</div>
                <div className={classes.threeTableTopColumn}>{t('products_col_07')}</div>
                <div className={classes.threeTableTopColumn}>{t('products_col_08')}</div>
              </div>
              <div className={classes.threeTableContent}>
                <div className={classes.threeTableContentColumn}>{t('products_row_01')}</div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
              </div>
              <div className={classes.threeTableContent}>
                <div className={classes.threeTableContentColumn}>
                  {t('products_row_02')}
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
              </div>
              <div className={classes.threeTableContent}>
                <div className={classes.threeTableContentColumn}>
                  {t('products_row_03')}
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
              </div>
              <div className={classes.threeTableContent}>
                <div className={classes.threeTableContentColumn}>{t('products_row_04')}</div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
              </div>
              <div className={classes.threeTableContent}>
                <div className={classes.threeTableContentColumn}>{t('products_row_05')}</div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
              </div>
              <div className={classes.threeTableContent}>
                <div className={classes.threeTableContentColumn}>
                  {t('products_row_06')}
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
              </div>
              <div className={classes.threeTableContent}>
                <div className={classes.threeTableContentColumn}>
                  {t('products_row_07')}
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
              </div>
              <div className={classes.threeTableContent}>
                <div className={classes.threeTableContentColumn}>{t('products_row_08')}</div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgYes} alt="" />
                </div>
                <div className={classes.threeTableContentColumn}>
                  <img src={imgNo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
