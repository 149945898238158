import React from "react";
import classes from './ComplexFinancial.module.scss';
import { useTranslation } from "react-i18next";

const ComplexFinancial = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.complexFinancial}>
                <div className={classes.headers}>
                    <h2 className={[classes.title, 'font-30'].join(' ')}>
                        {t('complex_title')}
                    </h2>
                    <p className={[classes.text, 'font-18'].join(' ')}>
                        {t('complex_text')}
                    </p>
                </div>
                <div className={classes.content}>
                    <div className={classes.block}>
                        <h3 className={[classes.subtitle, 'font-20'].join(' ')}>
                            {t('complex_subtitle_01')}
                        </h3>
                        <p className="font-18">
                            {t('complex_text_01')}
                        </p>
                    </div>
                    <div className={classes.block}>
                        <h3 className={[classes.subtitle, 'font-20'].join(' ')}>
                            {t('complex_subtitle_02')}
                        </h3>
                        <p className="font-18">
                            {t('complex_text_02')}
                        </p>
                    </div>
                    <div className={classes.block}>
                        <h3 className={[classes.subtitle, 'font-20'].join(' ')}>
                            {t('complex_subtitle_04')}
                        </h3>
                        <p className="font-18">
                            {t('complex_text_03')}
                        </p>
                    </div>
                    <div className={classes.block}>
                        <h3 className={[classes.subtitle, 'font-20'].join(' ')}>
                            {t('complex_subtitle_04')}
                        </h3>
                        <p className="font-18">
                            {t('complex_text_04')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ComplexFinancial;
