import React from "react";
import classes from "./Copy.module.scss";
import img1 from "../../../assets/img/Trading/3/1.png";
import img1_b from "../../../assets/img/Trading/3/1_b.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Copy = () => {
  const { t } = useTranslation();
  const blackTheme = useSelector((state) => state.theme.value);
  return (
    <div className={classes.copy}>
      <div className={classes.copyBody}>
        <div className="container">
          <div className={classes.copyRow}>
            <div className={classes.copyRowLeft}>
              <h2 className={[classes.copyRowLeftTitle, "font-40"].join(" ")}>
                {t("copy_trading_title")}
              </h2>
              <p className={[classes.copyRowLeftSubtitle, "font-30"].join(" ")}>
                {t("copy_trading_subtitle")}
              </p>
              <ul className={classes.copyRowLeftList}>
                <li
                  className={[classes.copyRowLeftListItem, "font-20"].join(" ")}
                >
                  {t("copy_trading_li_01")}
                </li>
                <li
                  className={[classes.copyRowLeftListItem, "font-20"].join(" ")}
                >
                  {t("copy_trading_li_02")}
                </li>
                <li
                  className={[classes.copyRowLeftListItem, "font-20"].join(" ")}
                >
                  {t("copy_trading_li_03")}
                </li>
              </ul>
            </div>
            <div className={classes.copyRowRight}>
              <img src={!blackTheme ? img1 : img1_b} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Copy;
