import React, { useEffect, useRef } from "react";
import classes from "./WhatsItAll.module.scss";
import tesla from "../../../assets/img/MainPage/WhatsItAll/card_tesla.png";
import amazon from "../../../assets/img/MainPage/WhatsItAll/card_amazon.png";
import apple from "../../../assets/img/MainPage/WhatsItAll/card_apple.png";
import graph from "../../../assets/img/MainPage/WhatsItAll/graph.png";
import tesla_b from "../../../assets/img/MainPage/WhatsItAll/card_tesla_b.png";
import amazon_b from "../../../assets/img/MainPage/WhatsItAll/card_amazon_b.png";
import apple_b from "../../../assets/img/MainPage/WhatsItAll/card_apple_b.png";
import graph_b from "../../../assets/img/MainPage/WhatsItAll/graph_b.png";
import mob_image from "../../../assets/img/MainPage/WhatsItAll/mob_image.png";
import mob_image_b from "../../../assets/img/MainPage/WhatsItAll/mob_image_b.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
gsap.registerPlugin(ScrollTrigger);

const WhatsItAll = () => {
  const { t } = useTranslation();
  const whatsItAllRef = useRef(null);
  const graphRef = useRef(null);
  const amazonRef = useRef(null);
  const teslaRef = useRef(null);
  const appleRef = useRef(null);
  const blackTheme = useSelector((state) => state.theme.value);
  useEffect(() => {
    gsap.timeline().fromTo(
      amazonRef.current,
      {
        y: 50,
      },
      {
        y: 0,
        scrollTrigger: {
          trigger: whatsItAllRef.current,
          start: "bottom bottom",
          end: "+=400px",
          scrub: 2,
        },
      }
    );
    gsap.timeline().fromTo(
      teslaRef.current,
      {
        y: 100,
      },
      {
        y: 0,
        scrollTrigger: {
          trigger: whatsItAllRef.current,
          start: "bottom bottom",
          end: "+=400px",
          scrub: 2,
        },
      }
    );
    gsap.timeline().fromTo(
      appleRef.current,
      {
        y: 150,
      },
      {
        y: 0,
        scrollTrigger: {
          trigger: whatsItAllRef.current,
          start: "bottom bottom",
          end: "+=400px",
          scrub: 2,
        },
      }
    );
  });
  return (
    <section ref={whatsItAllRef} className="container">
      <div className={classes.whatsItAll}>
        <div className={classes.content}>
          <h2 className={[classes.title, "font-40"].join(" ")}>
            {t("what_it_all_title")}
          </h2>
          <br />
          <h3 className={[classes.subtitle, "font-30"].join(" ")}>
            {t("what_it_all_subtitle")}
          </h3>
          <div className={[classes.text, "font-20"].join(" ")}>
            <p>{t("what_it_all_text_01")}</p>

            <p>{t("what_it_all_text_02")}</p>
          </div>
        </div>
        <div className={classes.images}>
          <div>
            <img
              ref={graphRef}
              className={classes.graph}
              src={!blackTheme ? graph : graph_b}
              alt=""
            />
          </div>
          <div>
            <img
              ref={amazonRef}
              className={classes.amazon}
              src={!blackTheme ? amazon : amazon_b}
              alt=""
            />
          </div>
          <div>
            <img
              ref={teslaRef}
              className={classes.tesla}
              src={!blackTheme ? tesla : tesla_b}
              alt=""
            />
          </div>
          <div>
            <img
              ref={appleRef}
              className={classes.apple}
              src={!blackTheme ? apple : apple_b}
              alt=""
            />
          </div>
        </div>
        <img
          className={classes.mob_image}
          src={!blackTheme ? mob_image : mob_image_b}
          alt=""
        />
      </div>
    </section>
  );
};

export default WhatsItAll;
