import React, { useEffect } from "react";
import classes from './MainPage.module.scss';
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from '../../components/MainPage/Banner/Banner';
import WhatsItAll from "../../components/MainPage/WhatsItAll/WhatsItAll";
import Advantages from "../../components/MainPage/Advantages/Advantages";
import Awards from "../../components/MainPage/Awards/Awards";
import StartNow from "../../components/MainPage/StartNow/StartNow";
import AccountTypes from "../../components/MainPage/AccountTypes/AccountTypes";
import Newsletters from "../../components/MainPage/Newsletters/Newsletters";

const MainPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="ovf-hidden">
            <div className={classes.bannerBg}>
                <Header />
                <Banner />
                <WhatsItAll />
                <Advantages />
                <Awards />
                <StartNow />
                <AccountTypes />
                <Newsletters />
                <Footer />
            </div>
        </div>
    );
};

export default MainPage;
