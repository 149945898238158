import React from "react";
import classes from "./First.module.scss";
import { Trans, useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.first}>
      <div className={classes.firstBody}>
        <div className="container">
          <div className={classes.firstWrapper}>
            <div className={[classes.firstTitle, "font-45"].join(" ")}>
              <Trans>
                {t('trading_universe_banner_title')}
              </Trans>
            </div>
            <div className={[classes.firstSubtitle, "font-25"].join(" ")}>
              {t('trading_universe_banner_text')}
            </div>
            <div className={classes.firstImg}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
