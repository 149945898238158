import React, { useEffect, useRef } from "react";
import classes from "./Banner.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import whale from "../../../assets/img/MainPage/Banner/whale.png";
import whale_b from "../../../assets/img/MainPage/Banner/whale_b.png";
import gsap from "gsap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Banner = () => {
  const themeBlack = useSelector((state) => state.theme.value);
  const { t } = useTranslation();
  const titleRef = useRef(null);
  const textRef = useRef(null);
  const btnRef = useRef(null);
  const whaleRef = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1221) {
      gsap.fromTo(
        titleRef.current,
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        textRef.current,
        {
          opacity: 0,
          y: 200,
        },
        {
          opacity: 1,
          y: 0,
          delay: 0.5,
          duration: 1,
        }
      );
      gsap.fromTo(
        btnRef.current,
        {
          opacity: 0,
          y: 300,
        },
        {
          opacity: 1,
          y: 0,
          delay: 0.5,
          duration: 1,
        }
      );
      if (t("lang") === "ar") {
        gsap.fromTo(
          whaleRef.current,
          {
            opacity: 0,
            x: -200,
            scaleX: -1,
          },
          {
            opacity: 1,
            x: 0,
            delay: 0.5,
            duration: 1,
          }
        );
      } else {
        gsap.fromTo(
          whaleRef.current,
          {
            scaleX: 1,
            opacity: 0,
            x: 200,
          },
          {
            opacity: 1,
            x: 0,
            delay: 0.5,
            duration: 1,
          }
        );
      }
    }
  }, [t("lang")]);
  return (
    <section className={classes.banner}>
      <div className="container">
        <h1 ref={titleRef} className={[classes.title, "font-45"].join(" ")}>
          {t("main_banner_title")}
        </h1>
        <h3 ref={textRef} className={[classes.text, "font-25"].join(" ")}>
          {t("main_banner_text")}
        </h3>
        <div ref={btnRef} className={classes.btn}>
          <Button>{t("btn_open_account")}</Button>
        </div>
      </div>

      <img
        ref={whaleRef}
        className={classes.whale}
        src={!themeBlack ? whale : whale_b}
        alt="Buy Shares with Prices You’ve Never Seen Before"
      />
    </section>
  );
};

export default Banner;
