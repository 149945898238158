import React, { useEffect, useRef } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './StartNow.module.scss';
import arm_left from '../../../assets/img/MainPage/StartNow/arm_left.png';
import arm_right from '../../../assets/img/MainPage/StartNow/arm_right.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const StartNow = () => {
    const { t } = useTranslation();
    const startNowRef = useRef(null);
    const armLeftRef = useRef(null);
    const armRightRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1221) {
            gsap.timeline().fromTo(armLeftRef.current, {
                x: -120,
                y: -50,
            }, {
                x: 0,
                y: 0,
                scrollTrigger: {
                    trigger: startNowRef.current,
                    start: "center bottom",
                    end: "+=300px",
                    scrub: 5,
                }
            });
        }
        if (document.documentElement.clientWidth > 1221) {
            gsap.timeline().fromTo(armRightRef.current, {
                x: 120,
                y: -50,
            }, {
                x: 0,
                y: 0,
                scrollTrigger: {
                    trigger: startNowRef.current,
                    start: "center bottom",
                    end: "+=300px",
                    scrub: 5,
                }
            });
        }
    }, []);
    return (
        <section ref={startNowRef} className={classes.startNow}>
            <div className="container">
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t('start_now_title')}
                </h2>
                <div className={classes.content}>
                    <h3 className={[classes.subtitle, 'font-30'].join(' ')}>
                        {t('start_now_subtitle')}
                    </h3>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>{t('start_now_text_01')}</p>
                        <p>{t('start_now_text_02')}</p>
                    </div>
                    <p className={classes.desc}>
                        <span>{t('start_now_note')}</span>: {t('start_now_note_text')}
                    </p>
                    <div className={classes.btn}>
                        <Button>{t('start_now_btn')}</Button>
                    </div>
                </div>
            </div>
            <img ref={armLeftRef} className={classes.arm_left} src={arm_left} alt=''/>
            <img ref={armRightRef} className={classes.arm_right} src={arm_right} alt=''/>
        </section>
    );
};

export default StartNow;
