import React, { useEffect, useState } from "react";
import classes from './TypesOf.module.scss';
import $ from 'jquery';
import { useTranslation } from "react-i18next";

const buttons = [
    {
        id: 1,
        name: 'types_of_subtitle_01',
    },
    {
        id: 2,
        name: 'types_of_subtitle_02',
    },
    {
        id: 3,
        name: 'types_of_subtitle_03',
    },
    {
        id: 4,
        name: 'types_of_subtitle_04',
    },
    {
        id: 5,
        name: 'types_of_subtitle_05',
    },
];

const content = {
    1: [
        'types_of_text_01',
    ],
    2: [
        'types_of_text_02',
    ],
    3: [
        'types_of_text_03',
    ],
    4: [
        'types_of_text_04',
    ],
    5: [
        'types_of_text_05',
    ],
};

const TypesOf = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(1);
    var initSize = function() {
        var start_element = $('.tabs-block li:first-of-type');
        var $label = $('.investAtTabs_label');
        var initWidth = start_element.css('width')	
        $label.css('width', initWidth);
    }
    useEffect(() => {
        initSize();
        $('.tabs-block li').on('click', function(){
            var $label = $('.investAtTabs_label');	
            var $this = $(this);
            var el_width = $this.width();
            var offset = $this.offset();
            $label
                .offset({ left: offset.left })
                .offset({ top: offset.top })
                .css('width', el_width)
        });
    }, []);
    return (
        <section className="container">
            <div className={classes.investAt}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t('types_of_title')}
                </h2>
                <h3 className={[classes.subtitle, 'font-18'].join(' ')}>
                    {t('types_of_text')}
                </h3>
                <div className={classes.tabs}>
                    <ul className={`${classes.buttons} tabs-block`}>
                        <div className={`${classes.label} investAtTabs_label`}></div>
                        {buttons.map((btn) =>
                            <li
                                key={btn.id}
                                onClick={() => setCurrentTab(btn.id)}
                                className={`${classes.btn} ${currentTab === btn.id && classes.btnActive}`}
                            >
                                <span>
                                    {t(btn.name)}
                                </span>
                            </li>
                        )}
                    </ul>
                    <div className={classes.content}>
                        {content[currentTab].map((par, index) =>
                            <p key={index}>{t(par)}</p>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TypesOf;
