import React from "react";
import classes from './SomeHistory.module.scss';
import image from '../../../assets/img/AboutPage/some_history.png';
import { useTranslation } from "react-i18next";

const SomeHistory = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.someHistory}>
                <div className={classes.col}>
                    <img src={image} alt=''/>
                </div>
                <div className={classes.col}>
                    <h2 className={[classes.title, 'font-30'].join(' ')}>
                        {t('some_history_subtitle')}
                    </h2>
                    <div className={[classes.text, 'font-18'].join(' ')}>
                        <p>{t('some_history_text_01')}</p>
                        <p>{t('some_history_text_02')}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SomeHistory;
