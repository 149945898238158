import React from "react";
import classes from './Advantages.module.scss';
import gif_01 from '../../../assets/img/MainPage/Advantages/card_01.gif';
import gif_02 from '../../../assets/img/MainPage/Advantages/card_02.gif';
import gif_03 from '../../../assets/img/MainPage/Advantages/card_03.gif';
import dollar from '../../../assets/img/MainPage/Advantages/dollar.png';
import Button from "../../GlobalComponents/Button/Button";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const cards = [
    {
        gif: gif_01,
        title: 'platform_advantages_card_title_01',
        text: 'platform_advantages_card_text_01',
        link: '/trading',
    },
    {
        gif: gif_02,
        title: 'platform_advantages_card_title_02',
        text: 'platform_advantages_card_text_02',
        link: '/about',
    },
    {
        gif: gif_03,
        title: 'platform_advantages_card_title_03',
        text: 'platform_advantages_card_text_03',
        link: '/accounts',
    },
];

const Advantages = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.advantages}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t('platform_advantages_title')}
                </h2>
                <h3 className={[classes.subtitle, 'font-30'].join(' ')}>
                    {t('platform_advantages_subtitle')}
                </h3>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('platform_advantages_text')}
                </p>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <div className={classes.bgWhite}></div>
                            <div className={classes.cardContent}>
                                <div className={classes.cardHeader}>
                                    <img className={classes.gif} src={card.gif} alt=''/>
                                    {index === 2 && <img className={classes.dollar} src={dollar} alt=''/>}
                                </div>
                                <h4 className={[classes.cardTitle, 'font-20'].join(' ')}>
                                    {t(card.title)}
                                </h4>
                                <p className={[classes.cardText, 'font-20'].join(' ')}>
                                    <Trans>{t(card.text)}</Trans>
                                </p>
                            </div>
                            <div className={classes.cardBtn}>
                                <Link className={classes.link} to={card.link}>
                                    <Button>{t('btn_learn_more')}</Button>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Advantages;
