import React from "react";
import classes from "./Steps.module.scss";
import { useTranslation } from "react-i18next";

const Steps = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.steps}>
      <div className={classes.stepsBody}>
        <div className="container">
          <h2 className={[classes.stepsTitle, "font-40"].join(" ")}>
            {t('steps_to_title')}
          </h2>
          <p className={[classes.stepsSubtitle, "font-30"].join(" ")}>
            {t('steps_to_subtitle')}
          </p>
          <div className={classes.stepsRow}>
            <div className={classes.stepsRowColumn}>
              <p className={classes.stepsRowColumnText}>{t('steps_to_step_01')}</p>
            </div>
            <div className={classes.stepsRowColumn}>
              <p className={classes.stepsRowColumnText}>{t('steps_to_step_02')}</p>
            </div>
            <div className={classes.stepsRowColumn}>
              <p className={classes.stepsRowColumnText}>
                {t('steps_to_step_03')}
              </p>
            </div>
            <div className={classes.stepsRowColumn}>
              <p className={classes.stepsRowColumnText}>
                {t('steps_to_step_04')}
              </p>
            </div>
            <div className={classes.stepsRowColumn}>
              <p className={classes.stepsRowColumnText}>{t('steps_to_step_05')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
