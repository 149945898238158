import React from "react";
import classes from './WhatType.module.scss';
import image_01 from '../../../assets/img/EducationPage/WhatType/card_01.png';
import image_02 from '../../../assets/img/EducationPage/WhatType/card_02.png';
import { Trans, useTranslation } from "react-i18next";

const WhatType = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.whatType}>
                <div className={classes.headers}>
                    <h2 className={[classes.title, 'font-30'].join(' ')}>
                        {t('what_type_title')}
                    </h2>
                    <p className={[classes.text, 'font-18'].join(' ')}>
                        <Trans>
                            {t('what_type_text')}
                        </Trans>
                    </p>
                </div>
                <div className={classes.cards}>
                    <div className={[classes.card, classes.card_01].join(' ')}>
                        <div className={classes.cardImage}>
                            <img className={classes.img} src={image_01} alt=''/>
                            <div className={classes.txt}>
                                <span>{t('what_type_subtitle_01')}</span>
                            </div>
                        </div>
                        <div className={classes.cardText}>
                            <p>{t('what_type_text_01')}</p>
                            <p>
                                <Trans>
                                    {t('what_type_text_02')}
                                </Trans>
                            </p>
                        </div>
                    </div>
                    <div className={[classes.card, classes.card_02].join(' ')}>
                        <div className={classes.cardText}>
                            <p>{t('what_type_text_03')}</p>
                            <p>{t('what_type_text_04')}</p>
                        </div>
                        <div className={classes.cardImage}>
                            <img className={classes.img} src={image_02} alt=''/>
                            <div className={classes.txt}>
                                <span>{t('what_type_subtitle_02')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhatType;
