import React from "react";
import classes from './AboutPage.module.scss';
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/AboutPage/Banner/Banner";
import TopFacts from "../../components/AboutPage/TopFacts/TopFacts";
import SomeHistory from "../../components/AboutPage/SomeHistory/SomeHistory";
import OurTop from "../../components/AboutPage/OurTop/OurTop";
import Awards from "../../components/MainPage/Awards/Awards";
import Newsletters from "../../components/MainPage/Newsletters/Newsletters";
import ContactUs from "../../components/AboutPage/ContactUs/ContactUs";

const AboutPage = () => {
    return (
        <div className="ovf-hidden">
            <div className={classes.pageBg}>
                <Header />
                <Banner />
                <TopFacts />
                <SomeHistory />
                <OurTop />
                <Awards />
                <ContactUs />
                <Newsletters />
                <Footer />
            </div>
        </div>
    );
};

export default AboutPage;
