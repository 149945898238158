import React from "react";
import { Trans } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import classes from './AccountTypes.module.scss';
import { useTranslation } from "react-i18next";

const rows = [
    [
        '$250',
        '$5,000',
        '$25,000',
        '$100,000',
        '$250,000',
        '$500,000',
    ],
    'account_types_at_row_02',
    [
        'EUR/USD 3.0 PIPS',
        'EUR/USD 2.9 PIPS',
        'EUR/USD 2.9 PIPS',
        'EUR/USD 2.7 PIPS',
        'EUR/USD 2.1 PIPS',
        'EUR/USD 1.6 PIPS',
    ],
    [
        'GBP/USD 3.4 PIPS',
        'GBP/USD 3.3 PIPS',
        'GBP/USD 3.2 PIPS',
        'GBP/USD 3.1 PIPS',
        'GBP/USD 2.5 PIPS',
        'GBP/USD 2.0 PIPS',
    ],
    [
        'USD/JPY 3.3 PIPS',
        'USD/JPY 3.1 PIPS',
        'USD/JPY 3.2 PIPS',
        'USD/JPY 3.0 PIPS',
        'USD/JPY 2.4 PIPS ',
        'USD/JPY 1.9 PIPS ',
    ],
    [
        'CRUDE OIL  $0.12',
        'CRUDE OIL  $0.12',
        'CRUDE OIL  $0.11',
        'CRUDE OIL $0.11',
        'CRUDE OIL $0.10',
        'CRUDE OIL $0.08',
    ],
    'account_types_at_row_03',
    [
        'CFDs Trading Tips',
        'CFDs Trading Tips',
        'CFDs Trading Tips',
        'CFDs Trading Tips',
        'CFDs Trading Tips',
        'CFDs Trading Tips',
    ],
    [
        'Tips for Forex CFD Trading',
        'Tips for Forex CFD Trading',
        'Tips for Forex CFD Trading',
        'Tips for Forex CFD Trading',
        'Tips for Forex CFD Trading',
        'Tips for Forex CFD Trading',
    ],
    [
        'CFD-Glossary',
        'CFD-Glossary',
        'CFD-Glossary',
        'CFD-Glossary',
        'CFD-Glossary',
        'CFD-Glossary',
    ],
    [
        'Technical Analysis',
        'Technical Analysis',
        'Technical Analysis',
        'Technical Analysis',
        'Technical Analysis',
        'Technical Analysis',
    ],
    [
        'Fundamental Analysis',
        'Fundamental Analysis',
        'Fundamental Analysis',
        'Fundamental Analysis',
        'Fundamental Analysis',
        'Fundamental Analysis',
    ],
    [
        'NFP',
        'NFP',
        'NFP',
        'NFP',
        'NFP',
        'NFP',
    ],
    [
        'Economic Calendar',
        'Economic Calendar',
        'Economic Calendar',
        'Economic Calendar',
        'Economic Calendar',
        'Economic Calendar',
    ],
    'account_types_at_row_04',
    [
        '1 Free Withdrawal',
        '2 Free Withdrawal',
        '5 free withdrawal',
        '1 Monthly Free Withdrawal',
        '3 Monthly Free Withdrawals',
        'No fees',
    ],
];

const AccountTypes = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.accountTypes}>
            <div className='container'>
                <div className={classes.headers}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('account_types_at_title')}
                    </h2>
                    <h3 className={[classes.subtitle, 'font-30'].join(' ')}>
                        {t('account_types_at_subtitle')}
                    </h3>
                </div>
            </div>
            <div className={[classes.container, 'container'].join(' ')}>
                <div className={classes.tableWr}>
                    <table>
                        <tbody>
                            <th colSpan="6">{t('account_types_at_row_01')}</th>
                            <tr className={classes.tableHeader}>
                                <td>{t('account_types_at_col_01')}</td>
                                <td>{t('account_types_at_col_06')}</td>
                                <td>{t('account_types_at_col_05')}</td>
                                <td>{t('account_types_at_col_02')}</td>
                                <td>{t('account_types_at_col_03')}</td>
                                <td>{t('account_types_at_col_04')}</td>
                            </tr>
                            {rows.map((row, index) => {
                                if (typeof(row) === 'string') {
                                    return (
                                        <th colSpan="6" key={index}>
                                            <Trans>
                                                {t(row)}
                                            </Trans>
                                        </th>
                                    );
                                }
                                return (
                                    <tr key={index}>
                                        {row.map((col, colIndex) =>
                                            <td key={colIndex}>
                                                <Trans>
                                                    {t(col)}
                                                </Trans>
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                            <tr className={classes.buttonsTr}>
                                <td>
                                    <Button>{t('account_types_card_01_btn')}</Button>
                                </td>
                                <td>
                                    <Button>{t('account_types_card_06_btn')}</Button>
                                </td>
                                <td>
                                    <Button>{t('account_types_card_05_btn')}</Button>
                                </td>
                                <td>
                                    <Button>{t('account_types_card_02_btn')}</Button>
                                </td>
                                <td>
                                    <Button>{t('account_types_card_03_btn')}</Button>
                                </td>
                                <td>
                                    <Button>{t('account_types_card_04_btn')}</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default AccountTypes;
