import React, { useRef, useEffect, useState } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./First.module.scss";
import gsap from "gsap";
import img1 from "../../../assets/img/StartPage/1/1.png";
import img1_b from "../../../assets/img/StartPage/1/1_b.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const First = () => {
  const text1Ref = useRef(null);
  const text2Ref = useRef(null);
  const text3Ref = useRef(null);
  const bullRef = useRef(null);
  const { t } = useTranslation();
  const blackTheme = useSelector((state) => state.theme.value);
  useEffect(() => {
    if (window.innerWidth > 1220) {
      gsap.to(bullRef.current, {
        opacity: 1,
        x: 0,
        delay: 1,
      });
      if (t("lang") === "ar") {
        gsap.fromTo(
          bullRef.current,
          {
            scaleX: -1,
            x: -200,
            duration: 1,
            opacity: 0,
          },
          {
            opacity: 1,
            x: 0,
            duration: 1,
            delay: 0.3,
          }
        );
      } else {
        gsap.fromTo(
          bullRef.current,
          {
            scaleX: 1,
            x: 200,
            duration: 1,
            opacity: 0,
          },
          {
            opacity: 1,
            x: 0,
            duration: 1,
            delay: 0.3,
          }
        );
      }
      gsap.to(text1Ref.current, {
        opacity: 1,
        y: 0,
        delay: 0.3,
      });
      gsap.to(text2Ref.current, {
        opacity: 1,
        y: 0,
        delay: 0.3,
      });
      gsap.to(text3Ref.current, {
        opacity: 1,
        y: 0,
        delay: 0.3,
      });
    }
  }, [t("lang")]);
  return (
    <div className={classes.first}>
      <div className={classes.firstBody}>
        <div className="container">
          <div className={classes.firstWrapper}>
            <div
              className={[classes.firstTitle, "font-45"].join(" ")}
              ref={text1Ref}
            >
              {t("start_banner_title")}
            </div>
            <div
              className={[classes.firstSubtitle, "font-25"].join(" ")}
              ref={text2Ref}
            >
              {t("start_banner_text")}
            </div>
            <div className={classes.firstBtn} ref={text3Ref}>
              <Button>{t("btn_start")}</Button>
            </div>
            <div className={classes.firstImg} ref={bullRef}>
              <img src={!blackTheme ? img1 : img1_b} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
