import React, { useState } from "react";
import classes from "./Leverage.module.scss";
import { useTranslation } from "react-i18next";

const Leverage = () => {
  const { t } = useTranslation();
  const [tabNumber, setTabNumber] = useState(1);

  return (
    <div className={classes.leverage}>
      <div className={classes.leverageBody}>
        <div className="container">
          <div className={[classes.leverageTitle, "font-40"].join(" ")}>
            {t('leverage_information_title')}
          </div>
          <div className={classes.leverageTopWrap}>
            <div className={classes.leverageTop}>
              <div
                className={
                  tabNumber === 1
                    ? [
                        classes.leverageTopColumn,
                        "font-20",
                        classes.active,
                      ].join(" ")
                    : [classes.leverageTopColumn, "font-20"].join(" ")
                }
                onClick={() => {
                  setTabNumber(1);
                }}
              >
                {t('leverage_information_tab_01_title')}
              </div>
              <div
                className={
                  tabNumber === 2
                    ? [
                        classes.leverageTopColumn,
                        "font-20",

                        classes.active,
                      ].join(" ")
                    : [classes.leverageTopColumn, "font-20", "font-20"].join(
                        " "
                      )
                }
                onClick={() => {
                  setTabNumber(2);
                }}
              >
                {t('leverage_information_tab_03_title')}
              </div>
              <div
                className={
                  tabNumber === 3
                    ? [
                        classes.leverageTopColumn,
                        "font-20",
                        classes.active,
                      ].join(" ")
                    : [classes.leverageTopColumn, "font-20"].join(" ")
                }
                onClick={() => {
                  setTabNumber(3);
                }}
              >
                {t('leverage_information_tab_02_title')}
              </div>
            </div>
          </div>
          <div
            className={
              tabNumber === 1
                ? [classes.leverageContentWrap, classes.active].join(" ")
                : [classes.leverageContentWrap].join(" ")
            }
          >
            <div className={classes.leverageContentWrapTable}>
              <div className={classes.leverageContentWrapTableTop}>
                <div className={classes.leverageContentWrapTableTopColumn}>
                  {t('leverage_information_tab_01_col_01')}
                </div>
                <div className={classes.leverageContentWrapTableTopColumn}>
                  {t('leverage_information_tab_01_col_02')}
                </div>
                <div className={classes.leverageContentWrapTableTopColumn}>
                  {t('leverage_information_tab_01_col_03')}
                </div>
              </div>
              <div className={classes.leverageContentWrapTableRow}>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  {" "}
                  {`< $1,000`}
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:3000
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:3000
                </div>
              </div>
              <div className={classes.leverageContentWrapTableRow}>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  $1,000 — $5,000
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:2000
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:2000
                </div>
              </div>
              <div className={classes.leverageContentWrapTableRow}>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  $5,000 — $10,000
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:1000
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:1000
                </div>
              </div>
              <div className={classes.leverageContentWrapTableRow}>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  $10,000 — $50,000
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:500
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:500
                </div>
              </div>
              <div className={classes.leverageContentWrapTableRow}>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  > $50,000
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:200
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:200
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              tabNumber === 2
                ? [classes.leverageContentWrap, classes.active].join(" ")
                : [classes.leverageContentWrap].join(" ")
            }
          >
            <div className={classes.leverageContentWrapTable}>
              <div className={classes.leverageContentWrapTableTop}>
                <div className={classes.leverageContentWrapTableTopColumn}>
                  {t('leverage_information_tab_01_col_01')}
                </div>
                <div className={classes.leverageContentWrapTableTopColumn}>
                  BTC/USD, BTC/EUR
                </div>
                <div className={classes.leverageContentWrapTableTopColumn}>
                  {t('leverage_information_tab_03_col_03')}
                </div>
              </div>
              <div className={classes.leverageContentWrapTableRow}>
                <div
                  className={classes.leverageContentWrapTableRowColumn}
                >{`< $250,000`}</div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:100
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:10
                </div>
              </div>
              <div className={classes.leverageContentWrapTableRow}>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  > $250,000
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:5
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:2
                </div>
              </div>
            </div>
            <div className={classes.leverageContentWrapText}>
              {t('leverage_information_tab_03_desc')}
            </div>
          </div>
          <div
            className={
              tabNumber === 3
                ? [classes.leverageContentWrap, classes.active].join(" ")
                : [classes.leverageContentWrap].join(" ")
            }
          >
            <div className={classes.leverageContentWrapTable}>
              <div className={classes.leverageContentWrapTableTop}>
                <div className={classes.leverageContentWrapTableTopColumn}>
                  {t('leverage_information_tab_01_col_01')}
                </div>
                <div className={classes.leverageContentWrapTableTopColumn}>
                  {t('leverage_information_tab_02_col_02')}
                </div>
              </div>
              <div className={classes.leverageContentWrapTableRow}>
                <div
                  className={classes.leverageContentWrapTableRowColumn}
                >{`< $250,000`}</div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:100
                </div>
              </div>
              <div className={classes.leverageContentWrapTableRow}>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  $250,000 — $500,000
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:50
                </div>
              </div>
              <div className={classes.leverageContentWrapTableRow}>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  > $500,000
                </div>
                <div className={classes.leverageContentWrapTableRowColumn}>
                  1:20
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leverage;
