import React, { useEffect, useState } from "react";
import classes from "./Funds.module.scss";
import $ from "jquery";
import img11 from "../../../assets/img/Trading/2/1/1.png";
import img21 from "../../../assets/img/Trading/2/2/1.png";
import img22 from "../../../assets/img/Trading/2/2/2.png";
import img23 from "../../../assets/img/Trading/2/2/3.png";
import img24 from "../../../assets/img/Trading/2/2/4.png";
import img31 from "../../../assets/img/Trading/2/3/1.png";
import img32 from "../../../assets/img/Trading/2/3/2.png";
import img33 from "../../../assets/img/Trading/2/3/3.png";
import { useTranslation } from "react-i18next";

const buttons = [
  {
    id: 1,
    name: "deposit_funds_tab_title_01",
  },
  {
    id: 2,
    name: "deposit_funds_tab_title_02",
  },
  {
    id: 3,
    name: "deposit_funds_tab_title_03",
  },
];

const Funds = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(1);
  var initSize = function () {
    var start_element = $(".tabs-block li:first-of-type");
    var $label = $(".investAtTabs_label");
    var initWidth = start_element.css("width");
    $label.css("width", initWidth);
  };
  useEffect(() => {
    initSize();
    $(".tabs-block li").on("click", function () {
      var $label = $(".investAtTabs_label");
      var $this = $(this);
      var el_width = $this.width();
      var offset = $this.offset();
      $label
        .offset({ left: offset.left })
        .offset({ top: offset.top })
        .css("width", el_width);
    });
  }, []);
  return (
    <section className="container">
      <div className={classes.investAt}>
        <h2 className={[classes.title, "font-40"].join(" ")}>{t('funds_withdrawal_title')}</h2>

        <div className={classes.tabs}>
          <ul className={`${classes.buttons} tabs-block`}>
            <div className={`${classes.label} investAtTabs_label`}></div>
            {buttons.map((btn) => (
              <li
                key={btn.id}
                onClick={() => setCurrentTab(btn.id)}
                className={`${classes.btn} ${
                  currentTab === btn.id && classes.btnActive
                }`}
              >
                <span>{t(btn.name)}</span>
              </li>
            ))}
          </ul>
          <div className={classes.content}>
            <div className={classes.deposit}>
              {currentTab === 1 && (
                <div className={classes.depositWrap}>
                  <div className={classes.depositTop}>
                    <div className={classes.depositTopColumn}>
                      {t('funds_withdrawal_col_01')}
                    </div>
                    <div className={classes.depositTopColumn}>{t('funds_withdrawal_col_02')}</div>
                    <div className={classes.depositTopColumn}>{t('funds_withdrawal_col_03')}</div>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_04')}
                    </div>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_05')}
                    </div>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_06')}
                    </div>
                  </div>
                  <div className={classes.depositContent}>
                    <div className={classes.depositContentColumn}>
                      <img src={img11} alt="" />
                    </div>
                    <div className={classes.depositContentColumn}>
                      VISA, Mastercard
                    </div>
                    <div className={classes.depositContentColumn}>
                      USD / EUR
                    </div>
                    <div className={classes.depositContentColumn}>2,5%</div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_up_to_24_hours')}
                    </div>
                    <div className={classes.depositContentColumn}>
                      20 USD / 20 EUR
                    </div>
                    <div className={classes.depositContentColumn}>
                      1,500 USD 2,400 EUR
                    </div>
                  </div>
                </div>
              )}
              {currentTab === 2 && (
                <div className={classes.depositWrap}>
                  <div className={classes.depositTop}>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_01')}
                    </div>
                    <div className={classes.depositTopColumn}>{t('funds_withdrawal_col_02')}</div>
                    <div className={classes.depositTopColumn}>{t('funds_withdrawal_col_03')}</div>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_04')}
                    </div>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_05')}
                    </div>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_06')}
                    </div>
                  </div>
                  <div className={classes.depositContent}>
                    <div className={classes.depositContentColumn}>
                      <img src={img21} alt="" />
                    </div>
                    <div className={classes.depositContentColumn}>USDT</div>
                    <div className={classes.depositContentColumn}>
                      TRC-20 ERC-20
                    </div>
                    <div className={classes.depositContentColumn}>
                      0,5% – 1%
                    </div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_up_to_24_hours')}
                    </div>
                    <div className={classes.depositContentColumn}>5 USDT</div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_unlimited')}
                    </div>
                  </div>
                  <div className={classes.depositContent}>
                    <div className={classes.depositContentColumn}>
                      <img src={img22} alt="" />
                    </div>
                    <div className={classes.depositContentColumn}>Bitcoin</div>
                    <div className={classes.depositContentColumn}>BTC</div>
                    <div className={classes.depositContentColumn}>
                      0,5% – 1%
                    </div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_up_to_24_hours')}
                    </div>
                    <div className={classes.depositContentColumn}>
                      0.002 BTC
                    </div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_unlimited')}
                    </div>
                  </div>
                  <div className={classes.depositContent}>
                    <div className={classes.depositContentColumn}>
                      <img src={img31} alt="" />
                    </div>
                    <div className={classes.depositContentColumn}>Litecoin</div>
                    <div className={classes.depositContentColumn}>LTC</div>
                    <div className={classes.depositContentColumn}>
                      0,5% – 1%
                    </div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_up_to_24_hours')}
                    </div>
                    <div className={classes.depositContentColumn}>0.02 LTC</div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_unlimited')}
                    </div>
                  </div>
                  <div className={classes.depositContent}>
                    <div className={classes.depositContentColumn}>
                      <img src={img24} alt="" />
                    </div>
                    <div className={classes.depositContentColumn}>Ethereum</div>
                    <div className={classes.depositContentColumn}>ETH</div>
                    <div className={classes.depositContentColumn}>
                      0,5% – 1%
                    </div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_up_to_24_hours')}
                    </div>
                    <div className={classes.depositContentColumn}>0.02 ETH</div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_unlimited')}
                    </div>
                  </div>
                </div>
              )}
              {currentTab === 3 && (
                <div className={classes.depositWrap}>
                  <div className={classes.depositTop}>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_01')}
                    </div>
                    <div className={classes.depositTopColumn}>{t('funds_withdrawal_col_02')}</div>
                    <div className={classes.depositTopColumn}>{t('funds_withdrawal_col_03')}</div>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_04')}
                    </div>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_05')}
                    </div>
                    <div className={classes.depositTopColumn}>
                    {t('funds_withdrawal_col_06')}
                    </div>
                  </div>
                  <div className={classes.depositContent}>
                    <div className={classes.depositContentColumn}>
                      <img src={img31} alt="" />
                    </div>
                    <div className={classes.depositContentColumn}>
                      Perfect Money
                    </div>
                    <div className={classes.depositContentColumn}>USD</div>
                    <div className={classes.depositContentColumn}>0,5%</div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_instant_availability')}
                    </div>
                    <div className={classes.depositContentColumn}>10 USD</div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_unlimited')}
                    </div>
                  </div>
                  <div className={classes.depositContent}>
                    <div className={classes.depositContentColumn}>
                      <img src={img32} alt="" />
                    </div>
                    <div className={classes.depositContentColumn}>Neteller</div>
                    <div className={classes.depositContentColumn}>USD/EUR</div>
                    <div className={classes.depositContentColumn}>
                      2% min 1 USD max 30 USD
                    </div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_instant_availability')}
                    </div>
                    <div className={classes.depositContentColumn}>
                      10 USD 10 EUR
                    </div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_unlimited')}
                    </div>
                  </div>
                  <div className={classes.depositContent}>
                    <div className={classes.depositContentColumn}>
                      <img src={img33} alt="" />
                    </div>
                    <div className={classes.depositContentColumn}>
                      TopChange
                    </div>
                    <div className={classes.depositContentColumn}>USD</div>
                    <div className={classes.depositContentColumn}>0%</div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_instant_availability')}
                    </div>
                    <div className={classes.depositContentColumn}>
                      10 USD 10 EUR
                    </div>
                    <div className={classes.depositContentColumn}>
                      {t('funds_withdrawal_unlimited')}
                    </div>
                  </div>
                  <div className={classes.depositContentText}>
                    {t('funds_withdrawal_desc')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Funds;
